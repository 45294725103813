
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IJobOffersWorker } from '@/types';
import {ECandidateOfferStatus} from "@/data";
import NewIcon from '@/components/NewIcon.vue';

@Component({
    name: "JobOfferCandidateAvailableCommands",
    components: {NewIcon}
})
export default class JobOfferCandidateAvailableCommands extends Vue {
    @Prop() readonly worker!: IJobOffersWorker;

    get displayableCommands() {
        return [
            ECandidateOfferStatus.APPLIED,
            ECandidateOfferStatus.TOSTUDY,
            ECandidateOfferStatus.PROPOSED,
            ECandidateOfferStatus.RETAINED,
            ECandidateOfferStatus.NOTRETAINED,
        ].filter((status: ECandidateOfferStatus) => status !== this.worker.statusInJobOffer)
    }

    // candidateChanged(candidate: ICandidateDB) {
    //     this.$emit('candidateChanged', candidate);
    // }

    updateStatus($event: ECandidateOfferStatus) {
        this.$emit("updateStatus", $event)
    }
}
