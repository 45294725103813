
import {Component, Vue, Prop} from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";

@Component({
    name: "OrderTile",
    components: {Tile}
})
export default class OrderTile extends Vue {
}
