
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import moment from 'moment-timezone';
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import { IInterviewDB } from '@/types';
import { CandidateCommand, MeetingResult, ROUTES } from '@/data';
import ActionButton from "@/components/ActionButton.vue";
import NewIcon from "@/components/NewIcon.vue";
import Tag from "@/components/Tag.vue";

@Component({
    name: "AfterMeetingModal",
    components: {NewIcon, ActionButton, CandidateProfileDisplay, Tag }
})
export default class AfterMeetingModal extends mixins(CandidateModalCommandMixin) {
    @Prop() loading!: boolean;
    name = "AfterMeetingModal";

    interview: IInterviewDB | null = null;
    comment: String = "";
    meetingResult: MeetingResult | null = null;

    MeetingResult = MeetingResult;

    get candidateInterviewDate() {
        if (this.candidateInterview) {
            return moment(this.candidateInterview.date).format(("DD/MM/YYYY"));
        }

        return '-';
    }

    get candidateInterview() {
        return this.candidate.interviews && this.candidate.interviews[this.candidate.interviews.length - 1];
    }

    onValidate(action:MeetingResult) {
        this.interview = this.candidateInterview;
        this.meetingResult = action
        this.validate();
    }
}
