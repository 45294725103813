
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {Getter, State} from "vuex-class";
    import clonedeep from "lodash.clonedeep";

    @Component({
        name: "SaveView"
    })
    export default class SaveView extends mixins(ModalMixin) {
        name = "SaveView";
        modelName = ""
        viewSelected= "";

        @Getter('userViews') allUserViews!:any;

        get viewsOptions() {
            return this.allUserViews(this.$route.name).map((view:any) => {
                return {
                    label: view.name,
                    value: view.nameSlug
                }
            })
        }

        createView() {
            this.$emit('create', this.modelName);
        }

        eraseView() {
            this.$emit('erase', this.viewSelected);
        }
    }
