
import {Component, Vue, Prop} from 'vue-property-decorator';
import Avatar from "@/components/Avatar.vue";

@Component({
    name: "CandidateProfileDisplay",
    components: {Avatar}
})
export default class CandidateProfileDisplay extends Vue {
    @Prop(String) candidateFullName!: string;
    @Prop(String) candidatePicture!: string;
}
