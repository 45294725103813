import { ref } from "vue";
import store from "@/store";
import { CandidateCommand } from "@/data";
import { IMessageTemplateDB } from "@/types";

interface IData {
    templates: IMessageTemplateDB[];
    name: string;
    candidateMessage: string;
    comment: string;
    collaborator: string;
    period: {
        from: number | null;
        to: number | null;
        date: number | null;
    };
    visualisations: {
        dateRange: {
            from: number;
            to: number;
        };
        mode: string;
    };
    error: any | null;
}

export const useCandidateScheduleMeeting = () => {
    const loading = ref(false);
    const submitScheduleMeeting = async ({ workerId, data }: { workerId: string; data: IData }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/sendCommandCandidate", {
                candidateId: workerId,
                command: CandidateCommand.scheduleMeeting,
                data: {
                    collaboratorId: data.collaborator,
                    date: data.period.date,
                    start: data.period.from,
                    end: data.period.to,
                    candidateMessage: data.candidateMessage,
                    collaboratorMessage: data.comment,
                },
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, submitScheduleMeeting };
};
