import { ref } from "vue";
import store from "@/store";
import { CandidateCommand } from "@/data";

interface IData {
    name: string;
    note: string;
    error: any | null;
}

export const useCandidateNoteAgency = () => {
    const loading = ref(false);
    const submitNoteAgency = async ({ workerId, data }: { workerId: string; data: IData }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/sendCommandCandidate", {
                candidateId: workerId,
                command: CandidateCommand.noteAgency,
                data: { data },
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, submitNoteAgency };
};
