
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import { IMessageTemplateDB, StoreAction } from '@/types';
import { Action } from 'vuex-class';
import { MessageTemplateType } from '@/data';
import ActionButton from "@/components/ActionButton.vue";
import NewIcon from "@/components/NewIcon.vue";
import Tag from "@/components/Tag.vue";

@Component({
    name: "RejectionModal",
    components: {Tag, NewIcon, ActionButton, CandidateProfileDisplay }
})
export default class RejectionModal extends mixins(CandidateModalCommandMixin) {
    name = "RejectionModal";
    @Prop() loading!: boolean;
    @Action("actions/getMessagesTemplates") getMessagesTemplates!: StoreAction;
    templates: Array<IMessageTemplateDB> = [] ;

    message:string = "";
    comment:string  = "";

    get templateOptions() {
        return [
            { label: "", value: "" },
            ...this.templates.map(template => {
                return { label: template.name, value: template.text };
            })
        ]
    }

    get modalTitle() {
        return this.$t('modals.reject.title');
    }

    get commentLabel() {
        return this.$t('modals.reject.optionalLabel');
    }

    async init() {
        try {
            this.templates = await this.getMessagesTemplates({ type: MessageTemplateType.ats_agency_rejection}) || [];
        } catch (e) {
            console.log(e);
        }
    }

    onValidate() {
        this.validate(this.errorHandlingCallback);
    }

    errorHandlingCallback(e:any) {
        this.error = e.errors;
    }

    beforeMount() {
        this.init();
    }
}
