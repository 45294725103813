
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from "moment-timezone";
import {capitalize, getFormattedHour, pluralize} from "@/helpers/commons";
import PlanningTile from '@/components/ats/tileContent/PlanningTile.vue';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import ActionButton from "@/components/ActionButton.vue";

@Component({
    name: "ScheduleSelectionCell",
    components: {ActionButton, PlanningTile }
})
export default class ScheduleSelectionCell extends Vue {
    @Prop() readonly cellData!: any;
    @Prop() readonly selectedSchedule: boolean = false;

    isOpen:boolean = false;
    error:any = null;

    capitalize = capitalize;
    pluralize = pluralize;
    moment = moment;

    period = {
        from: '',
        to: ''
    };

    get plannedInterviewText() {
        return this.$t('modals.toMeet.otherPlannedInterview', { nb: this.plannedInterviewNumber, meetingLabel: pluralize(<string>this.$t('modals.toMeet.meeting'), this.plannedInterviewNumber)});
    }

    get plannedInterviews() {
        return this.cellData.agenda.filter((d: any) => d.type === 'interview')
    }

    get plannedInterviewNumber() {
        return this.plannedInterviews.length;
    }

    get havePlannedInterview() {
        return  this.plannedInterviewNumber > 0;
    }

    get isToday() {
        const t = moment(this.cellData.timestamp);
        return t.date() === moment().date()
                    && t.month() === moment().month()
                    && t.year() === moment().year();
    }

    valideSchedule() {
        const from = parseInt(this.period.from.replace(':', ''));
        const to = parseInt(this.period.to.replace(':', ''));
        console.log(from, parseInt(this.moment(Date.now()).format("HHmm")), this.isToday);

        if (from < parseInt(this.moment(Date.now()).format("HHmm")) && this.isToday) {
            this.error = {
                id: "InterviewScheduling.InvalidStart"
            }
            return;
        }

        this.$emit('schedule', {
            from,
            to,
            date: this.cellData.timestamp
        });
        this.isOpen = false;
    }

    onClick() {
        if (!this.cellData.out) this.isOpen = true;
    }

    beforeMount() {
        const date = moment();
        // Round up to the nearest 10 minutes
        const roundedMinutes = Math.ceil(date.minutes() / 10) * 10;
        date.minutes(roundedMinutes);
        // Set the duration to 45 minutes
        const periodEnd = moment(date).add(45, 'minutes');
        // Format the period start and end times
        this.period = {
            from: getFormattedHour(parseInt(date.format('HHmm'))),
            to: getFormattedHour(parseInt(periodEnd.format('HHmm')))
        };
    }
}
