
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import { ROUTES } from '@/data';
import ActionButton from "@/components/ActionButton.vue";

@Component({
    name: "IntegrationModal",
    components: {ActionButton, CandidateProfileDisplay }
})
export default class IntegrationModal extends mixins(CandidateModalCommandMixin) {
    name = "IntegrationModal";
    @Prop() loading!: boolean;

    placed:boolean = false;

    get hasBeenInterviewed() {
        const interviews = this.candidate.interviews
        return interviews.length > 0 && interviews[interviews.length - 1].closed;
    }

    get goToCandidate() {
        return {name: ROUTES.ATS.CANDIDATE._ROOT, params: {candidateId: this.candidate._id}};
    }

    integrate() {
        this.integrateCandidate(false);
    }

    integratePlaced() {
        this.integrateCandidate(true);
    }

    integrateCandidate(placed: boolean) {

        if (!this.hasBeenInterviewed) {
            this.error = {
                id: "CandidateIntegration.NoInterview"
            }
            console.error("Candidate has not been interviewed");
            return;
        }

        if (placed && !this.candidate.jobOffer?.missionId) {
            this.error = {
                id: "CandidateIntegration.NoMission"
            }
            console.error("JobOffer without mission");
            return;
        }

        this.placed = placed;
        this.validate();
    }
}
