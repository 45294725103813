
import {Component, Prop, Vue} from "vue-property-decorator";
import Icon from "./Icon.vue";
import NewIcon from "./NewIcon.vue";

    @Component({
        name: "Avatar",
        components: { Icon, NewIcon }
    })
    export default class Avatar extends Vue {
        @Prop({default: ''}) readonly image!: string;
        @Prop({default: 'user'}) readonly mode!: string;

        get hasPopSlot() {
            return !!this.$slots.pop;
        }

        get imagePath() {
            if(!this.image) return '';
            if(this.image.startsWith('static')) {
                // @ts-ignore;
                return process.env.VUE_APP_API_URL.replace('/1.0', '') + "/" + this.image;
            }

            return this.image;
        }
    }

