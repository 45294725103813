
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import moment from "moment-timezone";
import { IMessageTemplateDB, LabelValue, StoreAction } from "@/types";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import { Action, Getter } from "vuex-class";
import { MessageTemplateType } from "@/data";
import Visualisations from "@/components/Visualisations.vue";
import Planning from "@/views/ATS/Following/Planning.vue";
import ActionButton from "@/components/ActionButton.vue";
import Calendar from "@/components/Calendar.vue";

@Component({
    name: "DateSchedulingModal",
    components: { ActionButton, CandidateProfileDisplay, Visualisations, Planning, Calendar },
})
export default class DateSchedulingModal extends mixins(CandidateModalCommandMixin) {
    @Prop() currentCollaborator!: any;
    @Getter("collaboratorsOptions") collaboratorsOptions!: LabelValue[];
    @Prop() loading!: boolean;
    @Action("actions/getMessagesTemplates") getMessagesTemplates!: StoreAction;

    templates: Array<IMessageTemplateDB> = [];

    name = "DateSchedulingModal";
    candidateMessage: string = "";
    comment: string = "";
    collaborator = this.currentCollaborator._id.toString();
    period = { date: null, from: null, to: null };
    visualisations: any = {
        dateRange: {
            from: moment().startOf("month").valueOf(),
            to: moment().endOf("month").valueOf(),
        },
        mode: "month",
    };

    get headers() {
        return moment.weekdays(true).map((d: string) => ({ label: d }));
    }

    get rows() {
        return [];
    }

    get collaborators() {
        return [
            {
                label: "Moi",
                value: this.currentCollaborator._id,
            },
            ...this.collaboratorsOptions,
        ];
    }

    get templateOptions() {
        return [
            { label: "", value: "" },
            ...this.templates.map((template) => {
                return { label: template.name, value: template.text };
            }),
        ];
    }

    async init() {
        try {
            this.templates = (await this.getMessagesTemplates({ type: MessageTemplateType.ats_agency_interview })) || [];
        } catch (e) {
            console.error(e);
        }
    }

    getPeriod(data: any) {
        this.period = data;
        this.error = null;
    }

    beforeMount() {
        this.init();
    }

    valideInterviewDate() {
        this.validate(this.errorHandling);
    }

    errorHandling(e: any) {
        // this.error = {};
        // Object.values(e.errors).forEach((e:any) => {
        //     this.error[e.key] = { ...e, id: "InterviewScheduling." + e.id };
        // })
        // console.log(e);
        this.error = {
            ...(e.errors.date || e.errors.end || e.errors.end),
            id: "InterviewScheduling." + (e.errors.date?.id || e.errors.start?.id || e.errors.end?.id),
        };
    }
}
