

import { StoreAction } from "@/types";
import moment from "moment-timezone";
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import { Action } from "vuex-class";
import { getFormattedHour, capitalize, pluralize } from "@/helpers/commons";
import ActionButton from "@/components/ActionButton.vue";
import PlanningTile from '@/components/ats/tileContent/PlanningTile.vue';

@Component({
        name: "Calendar",
        components: {
            ActionButton,
            PlanningTile
        }
    })
    export default class Calendar extends mixins(VModel) {
        @Prop() candidate : any;

        formatHour = getFormattedHour;
        capitalize = capitalize;
        pluralize = pluralize;

        agenda                : any     = [];
        $ref                            = {};
        date                            = new Date();
        startMonth            : any     = moment().startOf('month').toDate().valueOf();
        endMonth              : any     = moment().endOf('month').toDate().valueOf();
        interviews            : any[]   = []
        isOpen                : boolean = false;
        error                 : any     = null;
        plannedInterviewNb              = 0;
        plannedInterviews     : any     = []
        selectedDate          : any
        interviewRegisteredNb : number  = 0

        moment = moment

        period = {
            from: '',
            to: ''
        };

        @Action('actions/getCandidatesAgenda') getCandidateAgenda!: StoreAction;

        get havePlannedInterview() {
            return this.plannedInterviewNb > 0;
        }

        get plannedInterviewText() {
            return this.$t('modals.toMeet.otherPlannedInterview',
            { nb: this.plannedInterviewNumber, meetingLabel: pluralize(<string>this.$t('modals.toMeet.meeting'), this.plannedInterviewNumber)});
        }

        get plannedInterviewNumber() {
            return this.plannedInterviewNb;
        }

        get plannedInterview() {
            return this.plannedInterviews;
        }

        get formattedFilters() {
            return {
                from : this.startMonth,
                to   : this.endMonth
            }
        }

        get isToday() {
            const t = moment(this.date);
            return t.date() === moment().date()
                        && t.month() === moment().month()
                        && t.year() === moment().year();
        }

        changeMonth(m: any) {
            this.interviews = [];
            this.startMonth = moment([m.year, m.month - 1]).toDate().valueOf();
            this.endMonth   = moment(this.startMonth).endOf('month').toDate().valueOf();

            this.fillAgenda();
        }

        async fillAgenda() {
            try {
                this.agenda = await this.getCandidateAgenda(this.formattedFilters);
                this.fillInterviews();
            } catch (e) {

            }
        }

        fillInterviews() {
            if (this.agenda) {
                this.agenda.candidates.map((i: any) => {
                    const interview = {
                        dot      : true,
                        dates    : new Date(moment(i.interview.date)),
                        popover  : {
                            label         : "Entretien : " + capitalize(i.firstname) + ' à ' + this.formatHour(i.interview.start),
                            hideIndicator : true,
                            positionFixed : 'fixed',
                        },
                        firstname : i.firstname,
                        start     : i.interview.start,
                        end       : i.interview.end
                    }
                    this.interviews.push(interview);
                });
            }
        }

        selectDate(d: any) {
            if (d !== null) {
                this.selectedDate = d;
            }

            this.isOpen = true;

            const date = moment(this.selectedDate).startOf('day').valueOf();

            this.plannedInterviewNb = 0;
            this.plannedInterviews  = [];

            this.interviews.map((i: any) => {
                if (i.dates.valueOf() === date) {
                    this.plannedInterviewNb = this.plannedInterviewNb + 1;
                    this.plannedInterviews.push(i)
                }
            });
        }

        updateStartInterview() {
            if (parseInt(this.period.to) < parseInt(this.period.from)) {
                const endHour    = moment(this.period.to, 'HHmm');
                const startHour  = endHour.subtract(45, 'minutes');

                this.period.from = getFormattedHour(parseInt(startHour.format('HHmm')));
            }
        }

        updateEndInterview() {
            const startHour = moment(this.period.from, "HHmm");
            const endHour   = startHour.add(45, 'minutes')

            this.period.to  = getFormattedHour(parseInt(endHour.format('HHmm')));
        }

        valideSchedule() {
            const from = parseInt(this.period.from.replace(':', ''));
            const to = parseInt(this.period.to.replace(':', ''));

            if (from < parseInt(this.moment(Date.now()).format("HHmm")) && this.isToday) {
                this.error = {
                    id: "InterviewScheduling.InvalidStart"
                }
                return;
            }

            const data = {
                from : from,
                to : to,
                date: moment(this.selectedDate).startOf('day').valueOf()
            }

            const interview = {
                dot       : true,
                dates     : moment(this.selectedDate).startOf('day').valueOf(),
                firstname : this.candidate.firstname,
                id        : this.candidate._id,
                start     : from,
                end       : to
            }

            if (this.alreadyInterviewPlannedSameDay(interview) === true){
                return;
            }

            if (this.interviewRegisteredNb >= 1){
                this.interviews.pop();
            }

            this.interviews.push(interview);
            this.interviewRegisteredNb++;

            this.$emit('schedule', data)
            this.isOpen = false;
        }

        alreadyInterviewPlannedSameDay(interview: any) {
            const alreadyPlanned = this.interviews.find(i => {
                if(i.dates === interview.dates && i.id === interview.id) {
                    return true;
                }
            });

            if (alreadyPlanned) {
                this.$toast.open({
                    message: "Vous ne pouvez planifier plusieurs entretiens pour un candidat le même jour",
                    type: "error",
                    duration: 5000
                });
                this.isOpen = false;

                return true;
            }
        }

        beforeMount() {
            const date = moment();

            const roundedMinutes = Math.ceil(date.minutes() / 10) * 10;
            date.minutes(roundedMinutes);

            const periodEnd = moment(date).add(45, 'minutes');

            this.period = {
                from: getFormattedHour(parseInt(date.format('HHmm'))),
                to: getFormattedHour(parseInt(periodEnd.format('HHmm')))
            };
        }
    }
