
import {Component, Vue, Prop} from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";
import {IMissionDB, WorkerMission} from "@/types";
import {capitalize} from "@/helpers/commons";
import {ROUTES} from "@/data";

@Component({
    name: "MissionWorkerTile",
    components: {Tile}
})
export default class MissionWorkerTile extends Vue {
    @Prop() worker!: any;

    get fullName() {
        return `${capitalize(this.worker?.firstname)} ${capitalize(this.worker?.lastname)}`;
    }

    get jobName() {
        return capitalize(this.worker?.job?.name || '');
    }

    goToMission() {
        this.$router.push({name: ROUTES.APP.MISSION._ROOT, params: {missionId: this.worker.missionId}});
    }
}
