<style lang="scss">
.inputGmapAutocomplete {

    .input-label {
        display: flex;
        align-items: center;

        .Icon {
            position: relative;
            transform: initial;
            margin-bottom: 10px;
        }
    }

    label {
        width: 90px;
    }

    .fields {
        margin-top: calc($padding / 2);
    }

    .form-group.read>.input {
        display: flex;
        justify-content: space-between;
        padding: 0 calc($padding / 2);
    }

    .form-group {
        margin-bottom: calc($padding / 3);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.grey {
        input {
            border-color: $lightgrey;
        }
    }
}
</style>

<template>
    <div class="inputGmapAutocomplete grey">
        <div :class="`${groupClass} ${classes}`">
            <div class="input-label">
                <icon v-if="withIcon" :icon="'website'" :class="hasLocation ? 'green' : 'red'" />
                <label v-show="label" :for="name" class="label">
                    {{ label }}
                    <span v-if="required" style="color: #2051F8;"> *</span>
                </label>
            </div>
            <template v-if="contractManagerBlocked">
                <div :class="{ 'cm-blocked': contractManagerBlocked }" v-html="value" />
                <div v-if="contractManagerBlocked && !contractManagerBlockedNoWarning" class="cm-blocked-explanation">
                    {{ $t('contractManager.blockedField') }}</div>
            </template>
            <template v-else>
                <gmap-autocomplete ref="gmap" class="form-text GmapInput grey" :id="name" :name="name"
                    :autocomplete="'off'" :placeholder="placeholder" :value="value" :disabled="disabled"
                    @place_changed="place_changed" :selectFirstOnEnter="true" />
            </template>
        </div>
        <div>
        </div>
        <fade-transition mode="out-in">
            <error v-if="error" :error="error" />
        </fade-transition>
    </div>
</template>

<script>
export default {
    name: "InputGmapAutocomplete",
    data() {
        return {
            cur_value: '',
            place: {}
        };
    },
    props: {
        address: String,
        withIcon: Boolean,
        label: String,
        name: String,
        error: String,
        value: String,
        placeholder: String,
        disabled: Boolean,
        classes: String,
        contractManagerBlocked: String,
        contractManagerBlockedNoWarning: String,
        required: Boolean
    },
    computed: {
        groupClass() {
            return "form-group"
                + (this.error ? " form-error" : "")
        },
        hasLocation() {
            return this.place.latitude && this.place.longitude;
        }
    },
    methods: {
        place_changed(value) {
            this.place = {};

            this.place.name = value.name;
            this.place.formatted_address = value.formatted_address;
            this.place.geometry = value.geometry;
            if (value.geometry && value.geometry.location) {
                this.place.latitude = value.geometry.location.lat();
                this.place.longitude = value.geometry.location.lng();
            }
            this.place.place_id = value.place_id;

            if (value.address_components) {
                value.address_components.forEach(cmp => {
                    cmp.types.forEach(type => {
                        switch (type) {
                            case "country":
                                this.place.country = cmp.long_name;
                                this.place.countryCode = cmp.short_name;
                                break;
                            case "postal_code":
                                this.place.postal_code = cmp.long_name || cmp.short_name;
                                break;
                            case "locality":
                                this.place.locality = cmp.long_name;
                                break;
                            case "route":
                                this.place.street = cmp.long_name;
                                break;
                            case "formatted_address": break;
                            case "latitude": break;
                            case "longitude": break;
                            case "street_number":
                                this.place[type] = cmp.long_name;
                                break;
                            default: break;
                        }
                    })
                })
            }
            this.$emit('placeChanged', this.place);
        }
    },
    watch: {
        value(n) {
            this.cur_value = n;
        }
    },
    beforeMount() {
        this.place = {
            longitude: this.$props.address && this.$props.address.location && this.$props.address.location[0],
            latitude: this.$props.address && this.$props.address.location && this.$props.address.location[1]
        }
    },
    updated() {
        if (typeof this.value !== "undefined") {
            this.cur_value = this.value;
        }
    },
    mounted() {
        if (typeof this.value !== "undefined") {
            this.cur_value = this.value;
        }
    },
};
</script>
