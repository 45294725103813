import { ref } from "vue";
import store from "@/store";
import { CandidateCommand } from "@/data";

interface IData {
    name: string;
    comment: string;
    error: any | null;
}

export const useCandidateInterested = () => {
    const loading = ref(false);
    const submitInterested = async ({ data, workerId }: { data: IData; workerId: string }) => {
        try {
            loading.value = true;
            await store.dispatch("actions/sendCommandCandidate", {
                command: CandidateCommand.interested,
                candidateId: workerId,
                data,
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, submitInterested };
};
