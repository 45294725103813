
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from "moment-timezone";
import {capitalize, getFormattedHour, pluralize} from "@/helpers/commons";
import PlanningTile from "@/components/ats/tileContent/PlanningTile.vue";

@Component({
    name: "PlanningCell",
    components: { PlanningTile }
})
export default class PlanningCell extends Vue {
    @Prop() readonly cellData!: any;


    @Watch('cellData', {deep: true})
    onFilter() {
        this.init();
    }

    open = false;
    selectedTile = null;

    formatHour = getFormattedHour;
    capitalize = capitalize;
    pluralize = pluralize;
    moment = moment;

    get hasMultipleSchedule() {
        return this.cellData.agenda.length > 1;
    }

    get hasSchedule() {
        return this.cellData.agenda.length > 0;
    }

    isSelected(tile:any) {
        return tile === this.selectedTile ? 'selected' : '';
    }

    setSelected(tile:any) {
        this.selectedTile = tile;
    }

    init() {
        this.selectedTile = this.cellData.agenda[0];
    }

    beforeMount() {
        this.init();
    }
}
