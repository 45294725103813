
import {Component, Prop, Vue} from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";
import moment from "moment-timezone";
import {capitalize, getFormattedHour, pluralize} from "@/helpers/commons";
import { ROUTES } from '@/data';

@Component({
    name: "PlanningTile",
    components: {Tile}
})
export default class PlanningTile extends Vue {
    @Prop() readonly tileData!: any;
    @Prop() readonly mode!: string;
    @Prop() readonly color!: string

    open = false;

    formatHour = getFormattedHour;
    capitalize = capitalize;
    pluralize = pluralize;

    get isMission() {
        return this.mode === 'mission'
    }

    get isInterview() {
        return this.mode === 'interview';
    }

    get tileTextColor() {
        return this.isInterview ? "5A87B8" : "#C2AAE8";
    }

    get interviewSchedule() {
        const start = getFormattedHour(this.tileData.start);
        const end = getFormattedHour(this.tileData.end);
        return `${start} - ${end}`;
    }

    get missionSchedule() {
        const date = moment(this.tileData.mission.startAt);
        const start = getFormattedHour(this.tileData.mission.periods[0].slots.all[0].from);
        const end = getFormattedHour(this.tileData.mission.periods[0].slots.all[0].to)
        return `${date.format("DD/MM")} | ${start} - ${end}`;
    }

    get isFollowingPlanning() {
        return this.$route.name === ROUTES.ATS.FOLLOWING.PLANNING
    }

    redirectTo() {
        if (this.isFollowingPlanning) {
            if (this.isInterview) {
                this.$router.push({name: ROUTES.ATS.CANDIDATE._ROOT, params: {candidateId: this.tileData._id}});
            }
            else if (this.isMission) {
                this.$router.push({name: ROUTES.ATS.JOBOFFER._ROOT, params: {jobOfferId: this.tileData._id}});
            }
        }

    }
}
