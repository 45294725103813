
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
    name: "TitleManager"
})
export default class TitleManager extends Vue {
    pageTitle!: any;

    @Watch('pageTitle')
    onPageTitle() {
        this.onTitle(this.pageTitle);
    }

    onTitle(title: any) {
        this.pageTitle = title;
        this.$emit('title', title);
    }

    beforeMount() {
        this.$emit('title', this.pageTitle || "");
    }
}
