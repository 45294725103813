
import { Component, Prop, Vue } from "vue-property-decorator";
import Tile from "@/components/tileContent/Tile.vue";
import { CandidateLog, IWorkerAgency, LabelValue } from "@/types";
import { CandidateLogType, CandidateStatus, ROUTES } from "@/data";
import { Getter } from "vuex-class";
import moment from "moment-timezone";
import { capitalize, getFormattedHour, pluralize } from "@/helpers/commons";
import CandidateAvailableCommands from "@/components/ats/Candidate/CandidateAvailableCommands.vue";
import WorkerAvailableCommands from "@/components/Mission/WorkerAvailableCommands.vue";
import Avatar from "@/components/Avatar.vue";
import NewIcon from "@/components/NewIcon.vue";
import { parsePhoneNumber } from "libphonenumber-js";
import CandidateModalsActions from "../Candidate/CandidateModalsActions.vue";
import Tooltip from "../../Tooltip.vue";

@Component({
    name: "CandidateTile",
    components: { WorkerAvailableCommands, CandidateAvailableCommands, Tile, Avatar, NewIcon, CandidateModalsActions, Tooltip },
})
export default class CandidateTile extends Vue {
    @Prop() readonly candidate!: any;
    @Prop() readonly mode!: string;
    @Prop(Boolean) readonly noBottom!: boolean;
    @Prop(Boolean) readonly noCommands!: boolean;
    @Prop(Boolean) readonly noClick!: boolean;
    @Prop(Boolean) readonly noOpen!: boolean;

    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("collaboratorsOptions") collaboratorsOptions!: LabelValue[];
    @Getter("getJob") jobName!: any;
    @Getter("currentCollaborator") currentCollaborator!: any;
    @Getter("isSupervisor") isSupervisor!: boolean;

    open = false;

    formatHour = getFormattedHour;
    capitalize = capitalize;
    pluralize = pluralize;
    parsePhoneNumber = parsePhoneNumber;

    get candidateName() {
        const firstname = this.candidate.firstname;
        const lastname = this.candidate.lastname;
        return `${firstname ? firstname : ""} ${lastname ? lastname : ""}`;
    }

    get createdAt() {
        return moment(this.candidate.createdAt).format(this.$t("date.shortFormat"));
    }

    get elapsedTime() {
        return Math.trunc(moment.duration({ from: this.candidate.createdAt, to: Date.now() }).asDays());
    }

    get candidateMessageNumber() {
        return this.candidate.candidateLogs.filter((log: any) => log.type === "message").length;
    }

    get candidateAppliedJobName() {
        return this.candidate.jobOffer.job.name;
    }

    get hasMeeting() {
        return this.candidate.status === CandidateStatus.meeting;
    }

    get hasPassedMeeting() {
        const interview = this.candidate.interviews[this.candidate.interviews.length - 1];
        return interview && interview.date < moment.now() && !interview.closed;
    }

    get isIntegrated() {
        return this.candidate.status === CandidateStatus.integrated;
    }

    get isPlaced() {
        return this.candidate.placed;
    }

    get isRejected() {
        return this.candidate.status === CandidateStatus.rejected;
    }

    get isInteresting() {
        return this.candidate.status === CandidateStatus.interesting;
    }

    get meetingDate() {
        const date = moment(this.candidate.interviews[this.candidate.interviews.length - 1].date).format("DD/MM");
        const hour = this.formatHour(this.candidate.interviews[this.candidate.interviews.length - 1].start);
        return this.$t("page.atsFollowing.global.tile.interview.date", { date, hour, collaborator: capitalize(this.meetingOwnerInitial) });
    }

    get meetingOwner() {
        const meetingOwnerId = this.candidate.interviews[this.candidate.interviews.length - 1].collaboratorId;
        const collaborator = this.collaboratorsOptions.find((c: LabelValue) => c.value === meetingOwnerId);

        if (collaborator) return { firstname: collaborator.label.split(" ")[0], lastname: collaborator.label.split(" ")[1] };

        if (meetingOwnerId === this.currentCollaborator._id) return this.currentCollaborator;

        return this.$t("page.atsFollowing.global.tile.interview.oldCollaborator");
    }

    get meetingOwnerInitial() {
        if (typeof this.meetingOwner === "string") return this.meetingOwner;

        return this.ownerInitials(this.meetingOwner);
    }

    get rejectReason() {
        const log = this.candidate.candidateLogs.find((log: CandidateLog) => {
            return log.type === CandidateLogType.reject;
        });

        return (log && log.message) || "";
    }

    get elapsedTimeClass() {
        return `elapsed-time ${this.candidate.status}`;
    }

    get elapsedTimeTitle() {
        return this.elapsedTime > 0
            ? this.$t("page.atsFollowing.global.tile.elapsedTime.title", { elapsedTime: this.elapsedTime })
            : this.$t("page.atsFollowing.global.tile.new.title");
    }

    get elapsedTimeText() {
        return this.elapsedTime > 0
            ? pluralize(<string>this.$t("page.atsFollowing.global.tile.elapsedTime.text", { elapsedTime: this.elapsedTime }), this.elapsedTime)
            : this.$t("page.atsFollowing.global.tile.new.label");
    }

    get candidatMessages() {
        const str = this.$t("page.atsFollowing.global.tile.messages", { nb: this.candidateMessageNumber });
        return pluralize(<string>str, this.candidateMessageNumber);
    }

    get hasinfo() {
        return (
            (this.candidate.sourceJobService && this.candidate.source) ||
            (this.candidate.jobOffer && this.candidate.jobOffer.missionId) ||
            (this.candidate.jobOffer && this.candidate.jobOffer.jobId)
        );
    }

    get candidateMission() {
        return this.$t("page.atsFollowing.global.tile.mission", {
            name: this.candidate.jobOffer.mission.name,
            date: moment(this.candidate.jobOffer.missionStartDate).format("DD/MM"),
        });
    }

    get candidateOrigin() {
        return this.candidate.origin;
    }

    get candidatePhone() {
        return this.candidate.phone;
    }

    get job() {
        if (this.candidate.jobs.length > 0) {
            const job = this.getJob(this.candidate.jobs[0].jobId)?.label;
            if (this.candidate.jobs.length > 1) {
                return job + ", +" + (this.candidate.jobs.length - 1);
            }

            return job;
        }
    }

    get otherJobs() {
        let jobs = this.candidate.jobs.map((job: any) => {
            return this.getJob(job.jobId)?.label;
        });

        return jobs.join(", ").toString();
    }

    get agencyContactDetails() {
        return this.candidate.agencies.find((candidateAgency: IWorkerAgency) => {
            return candidateAgency.agencyId.toString() === this.currentCollaborator.agencyId.toString();
        });
    }

    get lastContact() {
        const agency = this.agencyContactDetails;
        if (!agency?.contactDetails) return;

        const contactDetailsArray: any[] = Object.values(agency.contactDetails);
        if (contactDetailsArray.length > 1) {
            contactDetailsArray.sort((contactDetailsA: any, contactDetailsB: any) => contactDetailsB.timestamp - contactDetailsA.timestamp);
        }

        return contactDetailsArray[0];
    }

    get lastLog() {
        if (!this?.lastContact?.type) return;

        switch (this.lastContact.type) {
            case "contact":
                return this.$t("page.atsFollowing.global.tile.interesting.contact", {
                    date: moment(this.lastContact.timestamp).format("DD/MM"),
                });
            case "voice-message":
                return this.$t("page.atsFollowing.global.tile.interesting.voiceMessage", {
                    date: moment(this.lastContact.timestamp).format("DD/MM"),
                });
            case "phone-report":
                return this.$t("page.atsFollowing.global.tile.interesting.phoneReport", {
                    date: moment(this.lastContact.timestamp).format("DD/MM"),
                });
        }
    }

    get contactDetails() {
        return Object.keys(this?.agencyContactDetails || {}).length || 0;
    }

    get lastLogClass() {
        return `log ${this?.lastContact?.type || ""}`;
    }

    get candidateJobOfferJob() {
        return this.$t("page.atsFollowing.global.tile.job", {
            job: this.getJob(this.candidate.jobOffer.jobId)?.label,
        });
    }

    get redirectTo() {
        return this.candidate.workerId ? this.goToInterim : this.goToCandidate;
    }

    getJob(id: string) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    ownerInitials(owner: any) {
        const firstname = owner.firstname;
        const lastname = owner.lastname;
        return `${firstname} ${lastname?.toUpperCase()}`;
    }

    emitCommand(result: any) {
        this.$emit("command", result);
    }

    goToCandidate() {
        this.$router.push({ name: ROUTES.ATS.CANDIDATE._ROOT, params: { candidateId: this.candidate._id } });
    }

    goToInterim() {
        this.$router.push({ name: ROUTES.APP.INTERIM, params: { interimId: this.candidate.workerId } });
    }

    goToMessages() {
        this.$router.push({ name: ROUTES.ATS.CANDIDATE._ROOT, query: { tabIndex: "2" }, params: { candidateId: this.candidate._id } });
    }

    goToCv() {
        this.$router.push({ name: ROUTES.ATS.CANDIDATE._ROOT, params: { candidateId: this.candidate._id } });
    }
}
