
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { IHeader, IMissionDB, ICandidateDB } from "@/types";
    import OrderTile from "@/components/tileContent/OrderTile.vue";
    import MissionTile from "@/components/tileContent/MissionTile.vue";
    import MissionWorkerTile from "@/components/tileContent/MissionWorkerTile.vue";
    import CandidateTile from "@/components/ats/tileContent/CandidateTile.vue";
    import moment from "moment-timezone";
    import clonedeep from "lodash.clonedeep";
    import debounce from 'lodash/debounce';

    @Component({
        name: "MegaTable",
        components: {MissionWorkerTile, MissionTile, OrderTile, CandidateTile}
    })
    export default class MegaTable extends Vue {
        @Prop() mode!: string;
        @Prop() headers!: IHeader[];
        @Prop() rows!: any;
        @Prop() loading!: boolean;
        @Prop(Boolean) collapsable!: boolean;
        @Prop({ default: 10 }) tilesPerColumn!: number
        visibleRows: any[] = []
        $refs!: {
            tiles: any[],
            rows: any[]
        };
        collapses = [];

        get isFollowing() {
            return this.mode === "following";
        }

        get isWeek() {
            return this.mode === "week";
        }

        get isMonth() {
            return this.mode === "month";
        }

        get isGant() {
            return this.isGantWorkers || this.isGantClients;
        }

        get isGantWorkers() {
            return this.mode === "gantt-workers";
        }

        get isGantClients() {
            return this.mode === "gantt-clients";
        }

        get isComingInterims() {
            return this.mode === "comingInterims";
        }

        get isJobOfferCandidates() {
            return this.mode === "joboffer-candidates";
        }

        get isPlanningWorkers() {
            return this.$route.query?.mode === "workers-availabilities";
        }

        get supressScrollX() {
            return this.isFollowing || this.isWeek;
        }

        get supressScrollY() {
            return false;
        }

        today(index:number) {
            if (this.isGant || this.isWeek || this.isComingInterims || this.isPlanningWorkers) {
                let t = moment(this.headers[index].value);

                return t.date() === moment().date()
                        && t.month() === moment().month()
                        && t.year() === moment().year();
            }
        }

        hasFilter(header:any) {
            return header.filterable;
        }

        get scrollOptions() {
            return {
                suppressScrollX: this.supressScrollX,
                suppressScrollY: this.supressScrollY,
            }
        }

        getTop(row:any, index:number) {
            let current = 0;

            if (this.isPlanningWorkers) {
                for (let i = 0, len = this.rows.length; i < len; ++i) {
                    if (this.rows[i]?.unavailabilities?.length) {
                        if (i < index) {
                            current += 1;
                        }
                    }
                }
                return (current * 120);
            } else {
                for (let i = 0, len = this.rows.length; i < len; ++i) {
                    if (this.rows[i]?.sortedMissions?.length) {
                        if (i < index) {
                            current += this.rows[i]?.sortedMissions?.length;
                        }
                    }
                }
                return (current * (this.isGantWorkers ? 150 : 90));
            }
        }

        isCollapsed(index:number) {
            return this.collapses[index];
        }

        toggleCollapse(index:number) {
            this.$set(this.collapses, index, !this.collapses[index]);
            this.$forceUpdate();
        }

        missionChanged(index:number, index2:number, changedMission: IMissionDB) {
            this.$emit('command', {changedMission});
        }

        candidateChanged(index:number, index2:number, changedCandidate: ICandidateDB) {
            this.$emit('command', {changedCandidate});
        }

        //Probably need optimisation with indexes start
        calculateOffset() {
            if (this.mode !== 'gantt-clients') return;
                this.$nextTick(() => {
                    const rows = this.$refs['rows'];
                    if (rows) {
                        // Default offset from top
                        let prevOffset = 75;
                        //skip first row since no offset is needed
                        // @ts-ignore;
                        for (let idx = 1; idx < rows.length; ++idx) {
                            //get previous child to get offsetHeigth
                            // @ts-ignore;
                            const previousRow = rows[idx-1];
                            const prevTile = previousRow.querySelector('.MissionTile');
                            if (prevTile) {
                                // set Tile offset on planning
                                prevOffset += prevTile.offsetHeight + 15
                                // @ts-ignore;
                                rows[idx].style.top = prevOffset + 'px';
                            }
                        }
                    }
                })
        }

        updated() {
            this.calculateOffset();
        }


        beforeMount() {
            if(this.rows?.length > 0 && (this.isFollowing || this.isWeek)) {
                this.initVisibleRowsForFollowingMode()
            }
        }

        mounted() {
            if(this.$refs.tiles && (this.isFollowing || this.isWeek)) {
                this.addScrollEventListenersForFollowingMode()
            }
        }

        initVisibleRowsForFollowingMode() {
            for(const column of this.rows) {
                const newColumn = clonedeep(column.slice(0, this.tilesPerColumn))
                this.visibleRows.push(newColumn)
            }
        }


        addScrollEventListenersForFollowingMode() {
            for(let i = 0 ; i < this.$refs.tiles.length ; i++) {
                const tile = this.$refs.tiles[i]
                if(this.isScrollable(tile.$el)) {
                    tile.$el.addEventListener('scroll', debounce(this.updatePagination.bind(this, i), 30))
                }
            }
        }

        isScrollable(element: any) {
            return element.clientHeight !== element.scrollHeight
        }

        updatePagination(columnIndex: number) {
            const el = this.$refs.tiles[columnIndex].$el
            const scrollbarPercentage: number = Math.round(((el.clientHeight + el.scrollTop) / el.scrollHeight) * 100)
            if(scrollbarPercentage > 70 && this.visibleRows[columnIndex].length !== this.rows[columnIndex].length) {
                this.addRows(columnIndex)
            }
        }

        addRows(columnIndex: number) {
            this.$set(this.visibleRows, columnIndex, this.rows[columnIndex].slice(0, this.visibleRows[columnIndex].length + this.tilesPerColumn))
        }
    }
