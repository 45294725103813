
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import { Getter, State } from "vuex-class";
import { IAddress, IJobDB, LabelValue } from "@/types";
import RoundRadios from "@/components/RoundRadios.vue";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import { ROUTES, WorkerMissionStatus } from "@/data";
import TextInput from "./form/TextInput.vue";

@Component({
    name: "ViewFilter",
    components: { RoundRadios, ListAutocompleteSelect, SelectAutocomplete, InputGmapAutocomplete, TextInput },
})
export default class ViewFilter extends mixins(VModel) {
    @Prop(String) filter!: string;
    @Prop() visualisations!: any;

    @State("selectedView") selectedView!: any;

    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("businessSectorOptions") businessSectorOptions!: LabelValue[];
    @Getter("interimStatusOptions") interimStatusOptions!: LabelValue[];
    @Getter("notificationLevelOptions") notificationLevelOptions!: LabelValue[];
    @Getter("notificationTypeOptions") notificationTypeOptions!: LabelValue[];
    @Getter("missionAlertOptions") missionAlertOptions!: LabelValue[];
    @Getter("isAgency") isAgency!: boolean;
    @Getter("isCompany") isCompany!: boolean;
    @Getter("userContext") userContext!: string;
    @Getter("finalMissionStatuses") finalMissionStatuses!: LabelValue[];
    @Getter("contractTypeSearch") contractTypeSearch!: LabelValue[];

    yesNo: any = [
        {
            label: "-",
            _id: "undefined",
        },
        {
            label: this.$t("global.yes"),
            _id: "yes",
        },
        {
            label: this.$t("global.no"),
            _id: "no",
        },
    ];

    @Watch("innerValue")
    onMissionAlert(n: string, o: string) {
        if (this.filter === "missionAlert" && n !== o) {
            this.$emit("clientUpdate", { property: "missionAlert", value: n });
        }
    }

    get filteredInterimStatusOptions() {
        return this.interimStatusOptions.filter((opt: LabelValue) => {
            return (
                opt.value === "invited" ||
                opt.value === "reinvited" ||
                opt.value === "registered" ||
                opt.value === "imported" ||
                opt.value === "inactive" ||
                opt.value === "no_longer_registered"
            );
        });
    }

    get activatedClass() {
        return this.innerValue === "undefined"
            ? "purpleneon"
            : this.innerValue === "yes"
            ? "purple"
            : this.innerValue === "no"
            ? "darkblue2"
            : "lightgrey";
    }

    get isInterims() {
        return (
            this.$route.name === ROUTES.APP.INTERIMS ||
            this.$route.name === ROUTES.APP.INTERIMS_LIST ||
            this.$route.name === ROUTES.APP.INTERIMS_INVITATIONS ||
            this.$route.name === ROUTES.APP.INTERIMS_AVAILABILITIES ||
            this.$route.name === ROUTES.APP.INTERIMS_GESTION_INACTIFS
        );
    }

    get isPlanning() {
        return (
            this.$route.name === ROUTES.APP.PLANNING ||
            this.$route.name === ROUTES.APP.PLANNING_ORDER ||
            this.$route.name === ROUTES.APP.PLANNING_WORKERS ||
            this.$route.name === ROUTES.APP.PLANNING_COMPANIES
        );
    }

    get isAgencies() {
        return this.$route.name === ROUTES.APP.AGENCIES;
    }

    get isClients() {
        return this.$route.name === ROUTES.APP.CLIENTS;
    }

    get searchPlaceholder() {
        if (this.isCompany) {
            if (this.isPlanning) {
                return this.$t("page.planning.searchInterim");
            }
            if (this.isAgencies) {
                return this.$t("page.agencies.searchAgencies");
            }
        }
        if (this.isInterims) {
            // if (this.isJobView) {
            //     return this.$t('page.interims.searchJobs');
            // } else {
            // }
            return this.$t("page.interims.search");
        }
        if (this.isClients) {
            return this.$t("page.clients.searchClient");
        }

        return this.$t("page.following.search");
    }

    get selectableJobOptions() {
        return this.jobOptions.map((job) => {
            return {
                ...job,
                selectable: true,
            };
        });
    }

    get isJobView() {
        return this.visualisations?.view === "jobs";
    }

    placeChanged(place: any) {
        this.innerValue = place.locality;
    }

    onSelect(selected: string[]) {
        this.innerValue = selected[0];
    }
}
