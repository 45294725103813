import { ref } from "vue";
import store from "@/store";
import { CandidateCommand } from "@/data";
import { IMessageTemplateDB } from "@/types";

interface IData {
    name: string;
    templates: IMessageTemplateDB[];
    message: string;
    comment: string;
    error: any | null;
}

export const useCandidateReject = () => {
    const loading = ref(false);
    const submitReject = async ({ workerId, data, collaboratorId }: { workerId: string; data: IData; collaboratorId: string }) => {
        try {
            loading.value = true;
            const requestData = {
                collaboratorId,
                candidateMessage: data.message,
                comment: data.comment,
            };
            await store.dispatch("actions/sendCommandCandidate", {
                candidateId: workerId,
                command: CandidateCommand.reject,
                data: requestData,
            });
        } catch (e) {
            throw e;
        } finally {
            loading.value = false;
        }
    };
    return { loading, submitReject };
};
