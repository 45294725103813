
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import { State } from 'vuex-class';

@Component({
    name: "PageMixin"
})
export default class PageMixin extends mixins(TitleManager) {
    @Prop() viewLoading!: boolean;
    @Prop() selectedView!: any;
    @Prop(Boolean) innerPage!: boolean;

    @State('selectedView') storeSelectedView!: any;
}
