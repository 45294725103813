
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "Tile"
})
export default class Tile extends Vue {
    @Prop(String) readonly mode!: string;
    @Prop(Boolean) readonly disableScroll!: boolean;
}
